/* src/components/Dashboard/Dashboard.css */

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); /* Subtle gradient background */
  color: #333;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white for contrast */
  backdrop-filter: blur(8px); /* Glassmorphism effect */
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
}

/* Improved Typography for Titles and Intro */
.dashboard-title {
  text-align: center;
  font-weight: 700;
  color: #1976d2;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.dashboard-intro {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  color: #555;
}

/* Search Bar Styling */
.search-bar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.search-field {
  width: 300px;
}

/* Modules Grid & Icon Card Enhancements */
.modules-container {
  flex: 1;
  overflow-y: auto;
}

.icon-card {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  padding: 2rem 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.icon-card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.icon-card .icon {
  margin-bottom: 1rem;
  transition: color 0.3s;
}

.icon-card:hover .icon {
  color: #1976d2; /* Change icon color on hover */
}

.icon-card .label {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .dashboard-container {
    padding: 1rem;
  }

  .dashboard-title {
    font-size: 1.5rem;
  }

  .dashboard-intro {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .icon-card {
    padding: 1rem 0.5rem;
  }

  .icon-card .label {
    font-size: 0.9rem;
  }

  .search-field {
    width: 80%;
  }
}

/* src/index.css */
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #f7f7f7;
}
